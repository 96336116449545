
import axios from "axios";


const subscriberFormHandler = (email) => {
  let base_url = process.env.REACT_APP_LEAD_SERVICE_URL + "/subscriber?"
  axios
    .post(
      base_url + "email_address=" + email,
    )
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.error("error message: " + err.response);
    });
};

export default subscriberFormHandler;