import "../style.css";
import StepCount from "./StepCount";

function ProcessStep({ step = {}, stepCount = 1 }) {
  const { title = "", summary = "", icon1 = "" } = step;

  const goToStep = () => {
    document
      .getElementById(`step-${stepCount}`)
      .scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <div className="our-process-header-step" onClick={goToStep}>
      <StepCount stepCount={stepCount} />
      <img
        src={icon1}
        className="our-process-header-step-icon"
        alt="step-icon"
      />
      <h3 className="our-process-header-step-title">{title}</h3>
      <p>{summary}</p>
    </div>
  );
}

export default ProcessStep;
