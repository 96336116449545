
import axios from "axios";

const leadFormHandler = (values) => {
  let base_url = process.env.REACT_APP_LEAD_SERVICE_URL + "?"
  return axios
    .post(
      base_url + 
        "name="+values.name +
        "&email="+values.email +
        "&phone="+values.tel +
        "&comments="+values.comments 
    )
    .then((response) => {
      console.log(response);
      return {isSuccess: true}
    })
    .catch((err) => {
      console.error("error message: " + err);
      return {isSuccess: false}
    });
};

export default leadFormHandler;
