import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../../index.css";

import Home from "../Home";
import About from "../About";
import Process from "../Process";
import Faq from "../FAQ";
import Contact from "../Contact";
import GroveScreen from "../GroveScreen";
import ConnectSources from "../ConnectSources";
import SourcesComponent from "../SourcesComponent";
import Career from "../Career";
import Blog from "../Blog";
import BlogPage from "../Blog/BlogPage/BlogPage";
import Main from "../Main";
import Layout from '../Layouts/BaseLayout'
const Routers = () => {
  return (
 <Router>
      <Switch>
        <Route exact path="/">
          <Main />
        </Route>
        <Route path="/contact-us">
            <Contact />
          </Route>
        {/* <Layout>
        <Route path="/home">
          <Home />
        </Route>
        

          <Route path="/career">
            <Career />
          </Route>
          
          <Route path="/about">
            <About />
          </Route>

          <Route path="/our-process">
            <Process />
          </Route>

          <Route path="/connectSources">
            <ConnectSources />
          </Route>

          <Route path="/sourcesComponent">
            <SourcesComponent />
          </Route>

          <Route path="/FAQ">
            <Faq />
          </Route>

      

          <Route path="/groveScreen">
            <GroveScreen />
          </Route>

          <Route path="/blog">
            <Blog />
          </Route>

          <Route path="/blog:id">
            <BlogPage />
          </Route>
		    </Layout>          */}
      </Switch>
    </Router>


  
  );
};

export default Routers;
