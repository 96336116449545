import React, { useEffect, useState } from "react";
import BlogImg from "../../assets/img/blog/Blog_Background.jpg";
import TitleImg from "../../assets/img/blog/icons/Blog_title_img.svg";
import BlogCard from "./BlogCard/BlogCard";

import "./style.css";

import CardImg from "../../assets/img/blog/card/Blog-cards-img.jpg";
import Arrow from "../../assets/img/blog/icons/blog-arrow.svg";

const dataItem = {
  title: "We’re passionate about Ecommerce.",
  name: "Name Name",
  dateItem: "22/4/22",
  description:
    "After years of building and growing businesses our dedicated team of experts",
  cardImg: CardImg,
};

const mapData = [
  { ...dataItem, id: 1 },
  { ...dataItem, id: 2 },
  { ...dataItem, id: 3 },
  { ...dataItem, id: 4 },
  { ...dataItem, id: 5 },
  { ...dataItem, id: 6 },
  { ...dataItem, id: 7 },
  { ...dataItem, id: 8 },
  { ...dataItem, id: 9 },
];

const Blog = () => {
  const [page, setPage] = useState(1);

  const paginationItemCount = Math.ceil(mapData.length / 6);
  const mapPagination = () => {
    let paginationList = [];
    for (let i = 1; i <= paginationItemCount; i++) {
      paginationList.push(i);
    }
    return paginationList;
  };

  useEffect(() => {
    window.scroll(0, 420);
  },[page])
  useEffect(() => {
    window.scroll(0, 0);
  },[])

  return (
    <div className="blog-section">
      <div className="img-container-blog">
        <img className="img-blog-4" src={BlogImg} alt="Blog_Background" />
      </div>
      <div className="blog-title-wrapper  blog-title-wrapper__margin">
        <img className="blog-img" src={TitleImg} alt="blog-img" />
        <div className="description-title-blog">Headline Headline</div>
        <div className="description_blog ">
          <span>Every amazing journey begins with one small step. </span>Tell us
          a little bit about yourself, and we’ll analyze the data and tell you
          how to achieve growth.
        </div>
      </div>
      <div className="blog-cards-wrapper">
        {mapData.slice((page * 6) - 6, page * 6).map((dataCard, i) => {
          return (
            <BlogCard key={i} dataItem={dataCard} />
          );
        })}
      </div>

      <div className="pagination-item-wrapper">
        <img
          src={Arrow}
          alt="prevArrov"
          className="blog-prev-arrow"
          onClick={() => setPage((prev) => prev - 1)}
          style={{ display: page == 1 ? "none" : "block" }}
        />

        {mapPagination().map((paginationItem) => {
          return (
            <div
              onClick={() => setPage(paginationItem)}
              key={paginationItem}
              className={`pagination-item ${
                paginationItem == page ? "pagination-item-active" : null
              }`}
            >
              {paginationItem}
            </div>
          );
        })}
        {}
        <img
          src={Arrow}
          onClick={() => setPage((prev) => prev + 1)}
          alt="nextArrov"
          className="blog-next-arrow"
          style={{ display: page == paginationItemCount ? "none" : "block" }}
        />
      </div>

    </div>
  );
};

export default Blog;
