import styled, { keyframes } from 'styled-components';

export const StarContainer = styled.div`
  height: 100%;
  background:radial-gradient(ellipse at bottom,#000000 0%,#090A0F 100%);
  overflow: overlay;
`;

export const AnimateStar = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
`;

export const Star = styled.div`
  position: absolute;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: transparent;
  box-shadow: ${(props) => props.shadows};
  animation: ${AnimateStar} ${(props) => props.animationDuration}s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background: transparent;
    box-shadow: ${(props) => props.shadows};
  }
`;