import { useState, useEffect, useRef } from "react";
import "./styles.css";
import { withRouter, Link } from "react-router-dom";
import Logo from "../../assets/img/Logo.png";
import Logo2 from "../../assets/img/Logo1.svg";

const Header = ({ location }) => {
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY) {
        setGoingUp(true);
      }
      // if (prevScrollY.current < 75) {
      //   setGoingUp(false);
      // }
      prevScrollY.current = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const linkList = [
    { title: "Home", url: "/home" },
    { title: "Our Process", url: "/our-process" },
    { title: "About", url: "/about" },
    { title: "FAQ", url: "/FAQ" },
    { title: "Career", url: "/career" },
    { title: "Blog", url: "/blog" },
    { title: "Contact", url: "/contact-us" },
  ];

  if (
    location.pathname === "/aboutBrandsScreen" ||
    // location.pathname === "/groveScreen" ||
    location.pathname === "/connectSources" ||
    location.pathname === "/sourcesComponent"
  ) {
    return null;
  }
  return (
    <nav className={goingUp ? "nav-fixsed" : "nav"}>
      <div className="nav-inner-container">
        <Link to="/" style={{ textDecoration: "none" }}>
          {/* <img src={Logo} alt = "logo" className="brand-name"/> */}
          <img
            src={Logo2}
            alt="logo"
            className="header-logo-burger filter-white"
          />

          {/* <h2 className="brand-name"> Atomic</h2> */}
        </Link>
        {/* <img className="header-logo" alt="Atomic" src={logo}/> */}
        <ul className="ul">
          {linkList.map((item) => {
            return (
              <li className="li" key={item.title}>
                <Link
                  to={item.url}
                  className={
                    location.pathname === item.url ? "link-active" : "link"
                  }
                >
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(Header);
