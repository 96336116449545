import React, { useCallback, useEffect } from "react";
import { rootReducer } from '../../store/routReducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';


import { GlobalStyle } from "./styles";
import Routers from "../Router";

const store = createStore(rootReducer);

const App = () => {
  const currentWidth = window.innerWidth;
  const isLandscape = useCallback(()=>{
    if( currentWidth < 820 ){
    return  window.orientation === 0;
  }
 },[currentWidth]);

useEffect(()=>{
  isLandscape();
},[currentWidth]);

  return (
    <Provider store={store}>
       <GlobalStyle  />
        <Routers  />
    </Provider>
  );
};

export default App;