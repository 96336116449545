import React, { useState, useEffect } from 'react';
import { generateShadows } from './StarShadows';
import { Star, StarContainer } from './StarStyles';
import './index.css'
const StarLayout = ({ children }) => {

  const [shadowsSmall, setShadowsSmall] = useState(generateShadows(700));
  const [shadowsMedium, setShadowsMedium] = useState(generateShadows(200));
  const [shadowsBig, setShadowsBig] = useState(generateShadows(100));

  useEffect(() => {
    setShadowsSmall(generateShadows(700));
    setShadowsMedium(generateShadows(200));
    setShadowsBig(generateShadows(100));
  }, []); 

  return (
    <StarContainer>
      <Star size={1} shadows={shadowsSmall} animationDuration={50} />
      <Star size={2} shadows={shadowsMedium} animationDuration={100} />
      <Star size={3} shadows={shadowsBig} animationDuration={150} />
      <div className='star-children-container'>{children}</div>
    </StarContainer>
  );
};

export default StarLayout;
