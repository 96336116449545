import { USER_BRAND } from './type';
const initialState = {
    user: {
        name:"test a"
    }
};
export const userBrandsReducer= (state = initialState, action) => {
    switch (action.type) {
        case USER_BRAND:
            return {
                ...state, user: action.payload
            };
        default: return state;
    }
};
