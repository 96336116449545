import cn from "classnames";

const TextField = ({
	errorCondition,
	onChange,
	placeholder,
	value,
	errorText,
}) => {
	return (
		<div className="text-field-container input-column-6">
			<input
				className={cn("input-brend", {
					"input-brend-red": errorCondition,
				})}
				value={value}
				onChange={onChange}
				type="text"
				required
				placeholder={placeholder}
			/>
			{errorCondition ? <label> {errorText} </label> : ""}
		</div>
	);
};

export default TextField;
