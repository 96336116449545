import React, { useEffect, useState } from "react";
import "./style.css";
import { connect } from 'react-redux';

const HeaderLine = ({ valueNumber,userBrand }) => {
  const [value, setValue] = useState();
  useEffect(() => {
    setValue(valueNumber ? valueNumber : 0);
  }, [valueNumber]);
 console.log(userBrand);
  return (
    <div className="header-component">
      <div className="header-logo-menu">
        <h2 className="header-logo-menu">Atomic</h2>
      </div>
      <div className="heder-container">
        <div className="header-line-container">
          <input
            type="range"
            min="0"
            max="6"
            className="header-input"
            value={value}
          />
          <div className="line-text-container"></div>
        </div>
        <div className="heder-text-line-container">
          <p className={value === 0 || value > 0 ? "heder-text-hover" : "heder-text"}>
            Not yet eligible
          </p>
          <p className={value > 1 ? "heder-text-hover" : "heder-text"}>
            Eligible for funding
          </p>
          <p className={value > 3 ? "heder-text-hover" : "heder-text"}>
            Excellent chances
          </p>
          <p className={value === 6 ? "heder-text-hover" : "heder-text"}>
            You Rock!
          </p>
        </div>
      </div>
      <div className="header-nick-name-container">
        <h2 className="header-nick-name">{userBrand.name.split(" ").map(name=>name[0].toUpperCase())}</h2>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userBrand: state.user.user
  }
};

export default connect(mapStateToProps,null)(HeaderLine);

