import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {  useState } from "react";
import cn from "classnames";

import "../style.css";
import ProcessStep from "./ProccessStep";

const ProcessSlider = ({ steps = {} }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
	const settings = {
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		speed: 1000,
		autoplaySpeed: 5000,
		cssEase: "linear",
		beforeChange: (current, next) => setCurrentSlide(next)
	};

	return (
		<div className="steps-mobile">
			<div className={cn("line-container", {
					"line-begin": currentSlide === 0,
          "line-middle": currentSlide !== 0 && currentSlide !== steps.length-1,
          "line-end": currentSlide === steps.length-1,
				})}>
				<div className="line-img" />
			</div>
			<Slider {...settings} className="slider">
				{steps.map((step, index) => (
					<ProcessStep key={step.title} step={step} stepCount={index + 1} />
				))}
			</Slider>
		</div>
	);
};

export default ProcessSlider;
