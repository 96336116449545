import React, { useEffect,useRef } from "react";

import "./style.css";

const Modal = ({ children, showModal }) => {

    const modalRef = useRef();

  const closeModalHandler = (e) => {
    if(e.target.classList.contains('popup-black'))
        showModal(false);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
        document.body.style.overflow = "scroll";
    }
  }, []);

  return (
    <div className="modal_wrapper">
      <div   className="popup-black" onClick={(e) => closeModalHandler(e)}>
        <div ref={modalRef}  className="popup">
          <button
            className="close-modal-btn close-popup"
            onClick={() => showModal(false)}
          />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
