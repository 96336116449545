import React, { useEffect, useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createUserBrands } from "../../store/actions";
import validator from "validator";
import useLocalStorage from "../../hooks/useLocalStorege";
import { useHttp } from "../../hooks/useHttp";
import leadFormHandler from "../FormHandler/Contact";

import AtomicLogo from '../../assets/img/Atomic-logo.svg'
import TextField from "../Input/TextField";

import StarLayout from '../Layouts/StarsLayout/StarLayout'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const inputValues = {
    name: "",
    email: "",
    tel:'',
    comments: ''
  }
const AboutBrandsScreen = ({ createUserBrands }) => {
  const [values, setValue] = useState(inputValues);
  const [successRequest, setIsSuccessRequest] = useState(false)
  const [colorInput, setColor] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [user, setUser] = useLocalStorage("user", values);
  const [requestLoading,setRequestLoading] = useState(false);
  const { loading, error, request } = useHttp();

  const set = (name) => {
    return ({ target: { value } }) => {
      setValue((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  const clearForm = () => {
setValue(inputValues)
  }

const submitForm = async (e) => {
  e.preventDefault();
  setRequestLoading(true);
  if (values.name.trim() === "" || values.email.trim() === "") {
    console.log("values are bad " + JSON.stringify(values));
    notifyError()
    setColor(true);
    setRequestLoading(false);
  } else if (!validator.isEmail(values.email)) {
    notifyError()
    console.log("email is not valid " + values.email);
    setValidEmail(true);
    setRequestLoading(false);
  } else {
    console.log(
      "peace has prevailed. calling form handler with values " +
        JSON.stringify(values)
    );

    setUser(values);
    const {isSuccess} = await leadFormHandler(values)
    if(isSuccess) {
      notifySuccess()
    clearForm()
    setIsSuccessRequest(true);
    setRequestLoading(false);
    }
    else{
      notifySuccess()
      clearForm()
    setRequestLoading(false);
    }
   
  }
};

const notifySuccess = () => toast.success("The form has been successfully submitted!");
const notifyError = () => toast.error("Values are bad!",{autoClose: 2000});


  const HeaderDescription = () => {
    return (
      <div className="description-container ">
        <Link to={'/'}><img className="description-icon" src={AtomicLogo} alt="arrow" /></Link>
        <h1 className="description-title description-title__FAG">
         Unlock the Next Era of Digital Account Management!
        </h1>
        <h3 className="description-subtext description-subtext__FAG description-subtext__contact">
          {" "}
          <span>Welcome to the forefront of digital innovation. </span>
         At Atomic-Growth, we're not just predicting the future; we're creating it. Our groundbreaking technology, woven with the threads of Artificial Intelligence and Machine Learning, is set to redefine how multiple brands are managed in a seamless, efficient, and scalable way.
         
        </h3>
      </div>
    );
  };

  return (
    <div className="brands-screen">
      <ToastContainer 
      position="bottom-right"
      closeOnClick/>
      <StarLayout >
         <HeaderDescription />
      <div className="about-brend-text">
        <form className="about-brend-container">
          <div className="form-row">
            <TextField
              errorCondition={colorInput && values.name.trim() === ""}
              value={values.name}
              onChange={set("name")}
              placeholder="Name"
              errorText="Name must not be empty"
            />
            <TextField
              errorCondition={
                (colorInput && values.email.trim() === "") || validEmail
              }
              value={values.email}
              onChange={set("email")}
              placeholder="Email"
              errorText="Email is not correct"
            />
          </div>

          <div className="form-row">
             <TextField
              errorCondition={
                false
              }
              value={values.tel}
              onChange={set("tel")}
              placeholder="Phone number"
              errorText="Phone number is not correct"
            />
             <TextField
              errorCondition={
                false 
              }
              value={values.comments}
              onChange={set("comments")}
              placeholder="Comments"
              errorText="Comments is not correct"
            />
            

          </div>

          <button
            type="submit"
            className={`${requestLoading?"about-brend-btn-disabled":"about-brend-btn"}`}
            onClick={submitForm}
            disabled={loading && requestLoading}
          >
            {requestLoading?"...":"Let's Grow"}
          </button>
        </form>
      </div>
      </StarLayout>
    </div>
  );
};

const mapDispatchToProps = {
  createUserBrands,
};

export default connect(null, mapDispatchToProps)(AboutBrandsScreen);
