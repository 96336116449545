import React, { useState } from "react";
import "./style.css";
import { withRouter, Link } from "react-router-dom";
import validator from "validator";
import subscriberFormHandler from "../FormHandler/Newsletter";

import Facebook from "../../assets/img/social/Group 310.svg";
import Linkedin from "../../assets/img/social/Group 311.svg";
import Twitter from "../../assets/img/social/Group 312.svg";
import Modal from "../ModalComponent";

const Footer = ({ location }) => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const emailHandler = (e) => {
    e.preventDefault();
    if (email.trim() === "" || !validator.isEmail(email)) {
      setErrorEmail(true);
      setEmail("");
    }
    if (validator.isEmail(email)) {
      subscriberFormHandler(email);
      setEmail("");
    }
  };

  const linkList = [
    { title: "Home", url: "/home" },
    { title: "About", url: "/about" },
    { title: "FAQ", url: "/FAQ" },
    { title: "Career", url: "/career" },
    { title: "Contact", url: "/contact-us" },
    { title: "Blog", url: "/blog" },
  ];

  const socialMediaList = [
    { src: Facebook, title: "Facebook" },
    { src: Linkedin, title: "Linkedin" },
    { src: Twitter, title: "Twitter" },
  ];

  if (
    location.pathname === "/aboutBrandsScreen" ||
    location.pathname === "/groveScreen" ||
    location.pathname === "/connectSources" ||
    location.pathname === "/sourcesComponent"
  ) {
    return null;
  }
  return (
    <div className="footer">
      <div className="footer-column-container">
        <div className="footer-column">
          <Link to="/" style={{ textDecoration: "none" }}>
            <h2 className="brand-name"> Atomic</h2>
          </Link>
          <span>Taking your business to the next level</span>
        </div>
        <div className="footer-column">
          <ul className="contact-container">
            <li>
              <a href="mailto:info@atomic-growth.com">
                Email: info@atomic-growth.com
              </a>
            </li>
            
          </ul>
        </div>
        <div className="footer-column input-column">
          <div className="footer-input-text">
            <p>Sign up to our newsletter</p>
          </div>
          <div className="news-letter-container">
            <input
              type="email"
              placeholder="Email"
              className={errorEmail ? "footer-input-red" : "footer-input"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="footer-btn"
              onClick={(e) => {
                emailHandler(e);
                setShowModal(true);
              }}
            >
              Sign up
            </button>
          </div>
        </div>
      </div>

      <ul className="footer-link-container">
        {linkList.map((item) => {
          return (
            <li key={item.title}>
              <Link to={item.url}> {item.title}</Link>
            </li>
          );
        })}
      </ul>
      {/* <div className="footer-social-list">
				{socialMediaList.map((social) => {
					return (
						<img
							key={social.title}
							className="social-list-img"
							src={social.src}
							alt={social.title}
						/>
					);
				})}
			</div> */}
      <div className="footer-all-rights">
        <span>© Atomic 2022. All Rights Reserved.</span>
      </div>
      {showModal ? (
        validator.isEmail(email) ? (
          <Modal showModal={setShowModal}>
            <div className="modal-content">Your email received, thanks</div>
          </Modal>
        ) : (
          <Modal showModal={setShowModal}>
            <div className="modal-content">Your email is not valid.</div>
            <div className="modal-content">Please enter the correct email.</div>
          </Modal>
        )
      ) : null}
    </div>
  );
};

export default withRouter(Footer);
