import React, { useState, useEffect } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

import BackgroundImg from "../../../assets/img/blog/blogPage/Blog_read_more.jpg";
import BlogCard from "../BlogCard/BlogCard";
import Arrow from "../../../assets/img/blog/icons/blog-arrow.svg";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

import CardImg from "../../../assets/img/blog/card/Blog-cards-img.jpg";
import ComponentImg from "../../../assets/img/blog/blogPage/BlogComponent.jpg";

const dataItem = {
  title: "We’re passionate about Ecommerce.",
  name: "Name Name",
  dateItem: "22/4/22",
  description:
    "After years of building and growing businesses our dedicated team of experts",
  cardImg: CardImg,
};

const dataItem2 = {
  title: "TestCard",
  name: "Name Name",
  dateItem: "22/4/22",
  description:
    "After years of building and growing businesses our dedicated team of experts",
  cardImg: CardImg,
};

const mapData = [
  { ...dataItem2, id: 1, title: "TestCard1" },
  { ...dataItem, id: 2, title: "TestCard2" },
  { ...dataItem, id: 3, title: "TestCard3" },
  { ...dataItem, id: 4, title: "TestCard4" },
  { ...dataItem, id: 5, title: "TestCard5" },
  { ...dataItem, id: 6, title: "TestCard6" },
  { ...dataItem, id: 7, title: "TestCard7" },
  { ...dataItem, id: 8, title: "TestCard8" },
  { ...dataItem, id: 9, title: "TestCard9" },
  { ...dataItem, id: 10, title: "TestCard10" },
  { ...dataItem, id: 11, title: "TestCard11" },
];
const blogComponentData = {
  blogTitle: "Headline Headline",
  blogDescription:
    "After years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a",
  blogParagraf: [
    "After years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for",
    "After years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for successAfter years of building and growing businesses our dedicated team of experts created a formula for",
  ],
  img: ComponentImg,
};

const blogComponentArr = [1, 2];

const BlogPage = () => {
  const [page, setPage] = useState(1);
  const [countOfCards, setCountOfCards] = useState(3);
  const { id } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
  }, [id]);

  useEffect(() => {
    if (window.screen.width < 1170) {
      setCountOfCards(2);
    }
    if (window.screen.width < 780) {
      setCountOfCards(1);
    }
  }, []);

  const paginationItemCount = Math.ceil((mapData.length - 1) / countOfCards);

  const mapPagination = () => {
    let paginationList = [];
    for (let i = 1; i <= paginationItemCount; i++) {
      paginationList.push(i);
    }
    if (window.screen.width < 780) {
      const currenPage = page * countOfCards - countOfCards;
      if (!currenPage) {
        return [currenPage + 1, currenPage + 2, currenPage + 3];
      }
      if (mapData.length - 2 == currenPage) {
        return [currenPage - 1, currenPage, currenPage + 1];
      }

      return [currenPage, currenPage + 1, currenPage + 2];
    }
    return paginationList;
  };

  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    adaptiveHeight: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <div className="blog-page-section">
      <div className="img-container-blog blog-page">
        <img
          className="img-blog-4"
          src={BackgroundImg}
          alt="Blog_Page_Background"
        />
        <div className="blog-page-header-description">
          <div className="blog-page-header-title">
            We’re passionate about Ecommerce.
          </div>
          <div className="blog-page-header-icon_wrapper">
            <div className="blog-page-header-icon">Name Name</div>
            <div className="blog-page-header-icon">22/4/22</div>
          </div>
        </div>
      </div>

      <div className="blog-page__content_wrapper">
        <div className="blog-page__cotent-description">
          After years of building and growing businesses our dedicated team of
          experts created a formula for successAfter years of building and
          growing businesses our dedicated team of experts created a formula for
          successAfter years of building and growing businesses our dedicated
          team of experts created a formula for successAfter years of building
          and growing businesses our dedicated team of experts created a formula
          for successAfter years of building and growing businesses our
          dedicated team of experts created a formula for successAfter years of
          building and growing businesses our dedicated team of experts created
          a formula for
        </div>
        {blogComponentArr.map((i) => {
          return <BlogComponent key={i} data={blogComponentData} />;
        })}
      </div>

      <section className="blog-page__more-card">
        <div className="blog-page__more-card-title">
          You may also want to read
        </div>
        {countOfCards != 1 ? (
          <div className="blog-page__cards-wrapper">
            {mapData
              .filter((item, i) => {
                return item.id != id.slice(1);
              })
              .slice(page * countOfCards - countOfCards, page * countOfCards)
              .map((dataCard, i) => {
                return <BlogCard key={i} dataItem={dataCard} />;
              })}
          </div>
        ) : (
          <div className="slider-blog-mobile">
            <Slider {...settings} >
              {mapData
                .filter((item, i) => {
                  return item.id != id.slice(1);
                })
                .map((dataCard, i) => {
                  return <BlogCard key={i} dataItem={dataCard} />;
                })}
            </Slider>
          </div>
        )}
        {countOfCards != 1 ? (
          <div className="pagination-item-wrapper">
            <img
              src={Arrow}
              alt="prevArrov"
              className="blog-prev-arrow"
              onClick={() => setPage((prev) => prev - 1)}
              style={{ display: page == 1 ? "none" : "block" }}
            />

            {mapPagination().map((paginationItem) => {
              return (
                <div
                  onClick={() => setPage(paginationItem)}
                  key={paginationItem}
                  className={`pagination-item ${
                    paginationItem == page ? "pagination-item-active" : null
                  } ${
                    window.screen.width < 780
                      ? "pagination-item-wrapper__smoll"
                      : null
                  }`}
                >
                  {paginationItem}
                </div>
              );
            })}
            {}
            <img
              src={Arrow}
              onClick={() => setPage((prev) => prev + 1)}
              alt="nextArrov"
              className="blog-next-arrow"
              style={{
                display: page == paginationItemCount ? "none" : "block",
              }}
            />
          </div>
        ) : null}
      </section>
    </div>
  );
};

const BlogComponent = ({ data }) => {
  const { blogTitle, blogDescription, blogParagraf, img } = data;
  return (
    <section className="blog-component__wrapper">
      <div className="blog-page-header-title blog-component__title">
        {blogTitle}
      </div>
      <div className="blog-component__img-wrapper">
        <div className="blog-page__cotent-description blog-page__blog-component-description">
          {blogDescription}
        </div>
        <img src={img} alt="Blog component" className="blog-component__img" />
      </div>
      {blogParagraf.map((description, id) => {
        return (
          <p className="blog-page__cotent-description" key={id}>
            {description}
          </p>
        );
      })}
    </section>
  );
};

export default BlogPage;
