import React from "react";
import { useHistory } from "react-router-dom";

import "./BlogCard.css";

const BlogCard = ({ dataItem }) => {
  const { title, name, cardImg, dateItem, description, id} = dataItem;
  const navigate = useHistory();

  const relocaetHandler = () => {
    navigate.push(`/blog:${id}`)
  };

  return (
    <div className="blog-card-wrapper">
      <img className="blog-card-img" src={cardImg} alt="cardImg" />
      <div className="blog-card-description-wrapper">
        <div className="blog-card-title">{title}</div>
        <div className="blog-card-icons_wrapper">
          <span className="pen-icon">{name}</span>
          <span className="calendar-icon">{dateItem}</span>
        </div>
        <div className="blog-card-description">{description}</div>
        <div className="blog-card-link" onClick={relocaetHandler}>
          Read More
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
