import React from 'react'
import './main.css'
import AtomicLogo from '../../assets/img/Atomic-logo.svg'
import StarLayout from '../Layouts/StarsLayout/StarLayout'
import {  Link } from "react-router-dom";
const Main = () => {
  return (
    <StarLayout>
    <div className="atomic-container">
      <div className="atomic-logo"> 
        <img src={AtomicLogo} alt='atomic-logo'/>
      </div>
      <h1 className="atomic-title">Atomic-Growth</h1>
      <p className="atomic-subtitle">Unleash the Atomic Power of AI to Supercharge Your E-commerce Success.</p>
      <Link to={'/contact-us'} className="atomic-link">Find out more</Link>
    </div>
    </StarLayout>
  )
}

export default Main