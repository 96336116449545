import { React, useEffect, useState } from "react";
import "./styles.css";
import { useHistory } from "react-router-dom";

import CareerCart from "../CareerCart";

import Img from "../../assets/img/career/careers.svg";
import HeaderImg from "../../assets/img/career/header.png";
const { GoogleSpreadsheet } = require("google-spreadsheet");

const Career = () => {
  const history = useHistory();
  const toLeadPage = () => {
    history.push("/aboutBrandsScreen");
  };

  const [careers, getCareers] = useState("");

  useEffect(() => {
    const getAllCareers = async () => {
      try {
        // Initialize the sheet - doc ID is the long id in the sheets URL
        // const doc = new  (process.env.REACT_APP_GOOGLE_SPREADSHEET_CAREERS_ID);

        const doc = new GoogleSpreadsheet(
          process.env.REACT_APP_GOOGLE_SPREADSHEET_CAREERS_ID
        );
        console.log(doc);

        // Initialize Auth - see more available options at https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
        await doc.useServiceAccountAuth({
          client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
          private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
            /\\n/g,
            "\n"
          ),
        });

        await doc.loadInfo(); // loads document properties and worksheets
        console.log(doc.title);
        const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
        const rows = await sheet.getRows(); // can pass in { limit, offset }
        const allCareers = rows;
        getCareers(allCareers);
        console.log(careers);
      } catch (e) {
        console.error("Error: ", e);
      }
    };
    getAllCareers();
  }, []);
  useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

  return (
    <div className="career-section">
      <div className="career-img-container">
        <img src={HeaderImg} className="career-img-header" />
      </div>
      <div className="career-title-wraper">
        <div className="career-img-icon">
          <img src={Img} className="career-img" />
        </div>
        <h1 className="career-title"> Join Us</h1>
        <h2 className="career-title-h3">
          <span>Join our HQ office in the heart of Tel Aviv.</span> We’re all
          about providing our employees with a conducive environment so that
          they can learn, thrive, and most importantly.
        </h2>
      </div>
      <CareerCart careers={careers} />

      <div className="button-career">
        <h1 className="button-title-career">Still have a question? </h1>
        <button
          className="career-btn"
          onClick={(e) => {
            window.location =
              "mailto:careers@atomic-growth.com?subject=Contact Atomic Growth";
          }}
        >
          Contact us
        </button>
      </div>
    </div>
  );
};

export default Career;
